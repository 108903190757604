import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useQuery, ClientContext } from 'graphql-hooks';
import { UserContext } from 'modules/context';

export default function UserProvider({ children }) {
  const client = useContext(ClientContext);
  const [user, setUser] = useState({});

  let accessToken = localStorage.getItem('accessToken');
  const { data, refetch } = useQuery(`query currentUser {
    currentUser {
      _key
      loggedIn
      username
      role
      token
    }
  }`);

  useEffect(() => {
    if (data) {
      setUser(data?.currentUser);
      accessToken = localStorage.getItem('accessToken');
      if (accessToken === null) {
        if (data?.currentUser?.token !== null) {
          localStorage.setItem('accessToken', data?.currentUser?.token);
          client.setHeader(
            'Authorization',
            `Bearer ${data?.currentUser?.token}`
          );
        }
      }
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [refetch, accessToken]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
};
