import React, { Suspense } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UserProvider from 'providers/User';

import {
  About,
  Categories,
  Login,
  NotFound,
  Privacy,
  Questions,
  Quiz,
  QuizTypes
} from 'pages';
import SuspenseFallback from 'components/SuspenseFallback/SuspenseFallback';

export function App() {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <UserProvider>
        <Helmet
          defaultTitle={process.env.REACT_APP_HTML_TITLE}
          titleTemplate={`%s | ${process.env.REACT_APP_HTML_TITLE}`}
        />
        <Switch>
          <Route exact path="/" component={Quiz} />
          <Route exact path="/about" component={About} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/admin/categories" component={Categories} />
          <Route exact path="/admin/questions" component={Questions} />
          <Route exact path="/admin/quiztypes" component={QuizTypes} />
          <Route component={NotFound} />
        </Switch>
      </UserProvider>
    </Suspense>
  );
}

export default withRouter(App);
