import { lazy } from 'react';

export const imports = {
  About: () => import(/* webpackChunkName: "about" */ 'pages/About'),
  Categories: () =>
    import(/* webpackChunkName: "categories" */ 'pages/Categories'),
  Home: () => import(/* webpackChunkName: "home" */ 'pages/Home'),
  Login: () => import(/* webpackChunkName: "login" */ 'pages/Login'),
  NotFound: () => import(/* webpackChunkName: "not-found" */ 'pages/NotFound'),
  Privacy: () => import(/* webpackChunkName: "privacy" */ 'pages/Privacy'),
  Questions: () =>
    import(/* webpackChunkName: "questions" */ 'pages/Questions'),
  Quiz: () => import(/* webpackChunkName: "quiz" */ 'pages/Quiz'),
  QuizTypes: () =>
    import(/* webpackChunkName: "quizTypes" */ 'pages/QuizTypes'),
  Register: () => import(/* webpackChunkName: "register" */ 'pages/Register')
};

export const About = lazy(imports.About);
export const Categories = lazy(imports.Categories);
export const Home = lazy(imports.Home);
export const Login = lazy(imports.Login);
export const NotFound = lazy(imports.NotFound);
export const Privacy = lazy(imports.Privacy);
export const Questions = lazy(imports.Questions);
export const Quiz = lazy(imports.Quiz);
export const QuizTypes = lazy(imports.QuizTypes);
export const Register = lazy(imports.Register);
