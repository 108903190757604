import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAlignLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAt,
  faBan,
  faBook,
  faBullhorn,
  faCheck,
  faChevronCircleLeft,
  faChevronLeft,
  faChevronCircleRight,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCode,
  faCog,
  faEdit,
  faEnvelope,
  faEquals,
  faGlobe,
  faHeart,
  faIndustry,
  faInfo,
  faInfoCircle,
  faKey,
  faLightbulb,
  faMapMarkerAlt,
  faNewspaper,
  faPhoneVolume,
  faPlus,
  faQuestion,
  faThList,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faTimes,
  faUserCircle,
  faUserEdit,
  faUserShield
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendar as farCalendar,
  faCheckSquare,
  faHeart as farHeart,
  faSave,
  faSquare
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faAlignLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAt,
  faBan,
  faBook,
  faBullhorn,
  farCalendar,
  faCheck,
  faCheckSquare,
  faChevronCircleLeft,
  faChevronLeft,
  faChevronCircleRight,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCode,
  faCog,
  faEdit,
  faEnvelope,
  faEquals,
  faGlobe,
  faHeart,
  farHeart,
  faIndustry,
  faInfo,
  faInfoCircle,
  faKey,
  faLightbulb,
  faMapMarkerAlt,
  faNewspaper,
  faPhoneVolume,
  faPlus,
  faQuestion,
  faSave,
  faSquare,
  faThList,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faUserCircle,
  faUserEdit,
  faUserShield
);

export default library;
