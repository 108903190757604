import React, { Fragment, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Dropdown, Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClientContext } from 'graphql-hooks';
import { currentUser } from 'modules/hooks';

export default function Header() {
  const user = currentUser();
  const client = useContext(ClientContext);

  const userIcon = () => {
    return <FontAwesomeIcon icon="user-circle" />;
  };

  const logoutUser = () => {
    client.setHeader('Authorization', `Bearer `);
    localStorage.removeItem('accessToken');
    user.loggedIn = false;
  };

  return (
    <Fragment>
      <div className="navbar-underlay mb-2">
        <Navbar bg="light" variant="light" expand="lg">
          <div>
            <Navbar.Brand as={Link} to="/" className="font-weight-bolder ps-2">
              Quizter
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto mr-auto mt-2">
              <h3>
                <Nav.Link as={NavLink} to="/" title="Take Practice Quizzes">
                  Quizzes
                </Nav.Link>
              </h3>
              <h3>
                <Nav.Link as={NavLink} to="/about" title="About Quizter">
                  About
                </Nav.Link>
              </h3>
            </Nav>
            <Nav className="ms-md-3">
              {user?.role === 'admin' ? (
                <Dropdown className="me-1">
                  <Dropdown.Toggle variant="secondary" id="admin-dropdown">
                    Administrator
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to="/admin/quiztypes">
                      Quiz Types
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to="/admin/categories">
                      Categories
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to="/admin/questions">
                      Questions
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}

              {user?.loggedIn ? (
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="user-dropdown">
                    {userIcon()} {user.username}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to="/profile">
                      My Profile
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to="/messages">
                      Messages
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to="/account">
                      Account
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to="/connections">
                      Connections
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      as={NavLink}
                      to="/login"
                      onClick={() => logoutUser()}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Fragment>
                  <Nav.Link as={NavLink} to="/login">
                    Login
                  </Nav.Link>
                </Fragment>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </Fragment>
  );
}
