import { useContext } from 'react';
import { UserContext } from 'modules/context';

export const currentUser = () => useContext(UserContext);

const hooks = {
  currentUser
};

export default hooks;
