import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { GraphQLClient, ClientContext } from 'graphql-hooks';

import './icons';
import './index.scss';

import App from './components/App';

const token = localStorage.getItem('accessToken');
const headers = token !== null ? { Authorization: `Bearer ${token}` } : {};
const client = new GraphQLClient({
  url: process.env.REACT_APP_API_URL,
  headers
});

// import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Router basename="/">
    <ClientContext.Provider value={client}>
      <App />
    </ClientContext.Provider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
